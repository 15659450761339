import React, {useEffect, useState} from 'react'
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import {FaFacebook, FaTwitter, FaYoutube, FaTiktok} from 'react-icons/fa'
import { animateScroll as scroll} from 'react-scroll'
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
    NavBtn, 
    NavBtnLink,
    SocialIcons, 
    SocialIconLink,
    NavEmpty
} from './NavbarElements'

const Navbar = ({ toggle, showNavLink }) => {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    }else{
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }
  
  return (
    <>
      <IconContext.Provider value={{ color: '#fff'}}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>Somnolent Team</NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              {(showNavLink) ? 
              <NavItem>
                  <NavLinks to="home"
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}>Home</NavLinks>
              </NavItem> : <NavItem><NavEmpty>Home</NavEmpty></NavItem>}
              {(showNavLink) ? 
              <NavItem>
                  <NavLinks to="games"
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}>Games</NavLinks>
              </NavItem> : <NavItem><NavEmpty>Games</NavEmpty></NavItem>}
              {(showNavLink) ? 
              <NavItem>
                  <NavLinks to="about-us"
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}>About Us</NavLinks>
              </NavItem> : <NavItem><NavEmpty>About Us</NavEmpty></NavItem>}
                <SocialIcons>
                    <SocialIconLink href="//www.facebook.com/somnolent.team" target="_blank"
                    aria-label="Facebook">
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href="//www.twitter.com/SomnolentTeam" target="_blank"
                    aria-label="Twitter">
                        <FaTwitter />
                    </SocialIconLink>
                    <SocialIconLink href="//www.youtube.com/channel/UC8xkYTDK18wMOlpmUdpT2KA/?sub_confirmation=1" target="_blank"
                    aria-label="Youtube">
                        <FaYoutube />
                    </SocialIconLink>
                    <SocialIconLink href="https://www.tiktok.com/@somnolent.team" target="_blank"
                    aria-label="Tiktok">
                        <FaTiktok />
                    </SocialIconLink>
                </SocialIcons>
            </NavMenu>
            <NavBtn>
              <NavBtnLink href="mailto:somnolent.team@gmail.com?cc=kokkakniphon@gmail.com&subject=Contact us from somnolentteam.com">Contact Us</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar