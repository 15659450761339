import * as hoop_the_loop_game_icon from '../../images/games-title/hoop_the_loop_game_icon.png';
import * as sword_arena_game_icon from '../../images/games-title/sword_arena_game_icon.png';
import * as tactica_game_icon from '../../images/games-title/tactica_game_icon.png';
import * as timeout_hero_game_icon from '../../images/games-title/timeout_hero_game_icon.png';
import * as ranch_rusher_game_icon from '../../images/games-title/ranch_rusher_game_icon.png';
import * as alchemist_grove_game_icon from '../../images/games-title/alchemist_grove_game_icon.png';
import * as groundian_game_icon from '../../images/games-title/groundian_game_icon.png';
import * as switch_game_icon from '../../images/games-title/switch_game_icon.png';

import { profiles_data } from '../AboutUs/Data';

export const prev_projects_data = {
    
    hoopTheLoop: {
        icon: hoop_the_loop_game_icon.default,
        topLine: 'School Project',
        headline: 'Hoop The Loop',
        description: 'Another arcade basketball game? This one is quite different. Hoop the Loop is a casual basketball game that focuses on displaying the game physics calculation. Yes, the game is made for the physics class project, but it’s a simple fun game with cute paperwork art style.',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Designer"
            },
            {
                icon: profiles_data.niphon.icon,
                name: profiles_data.niphon.first,
                role: "Programmer"
            }
        ]
    },

    swordArena: {
        icon: sword_arena_game_icon.default,
        topLine: 'School Project',
        headline: 'Sword Arena',
        description: 'Sword Arena is a 1v1 top-down fighting game that combines the retro art style with the modern combat & camera system. Not just only the intense fights, the game also consists of 7 characters, each with unique flashy skills. Choose your hero and be the king of Sword Arena!',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Designer / Programmer"
            },
        ]
    },

    tactica: {
        icon: tactica_game_icon.default,
        topLine: 'School Project',
        headline: 'Tactica',
        description: 'Tactica is an online turn-based strategy multiplayer game with the delay battle system. The charming pixel art and our high-depth combat mechanic will keep you enjoy when using your big brain to beat your opponent.',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Designer / Programmer"
            },
            {
                icon: profiles_data.stamp.icon,
                name: profiles_data.stamp.first,
                role: "Programmer"
            },
            {
                icon: profiles_data.jan.icon,
                name: profiles_data.jan.first,
                role: "Art Director"
            },
        ]
    },

    timeoutHero: {
        icon: timeout_hero_game_icon.default,
        topLine: 'School Project',
        headline: 'Timeout Hero',
        description: 'Have you ever played a platformer game? Now you have to beat the level within 60 seconds. Play as Hero who is trying to rescue the princess but accidentally cursed to die every 60 seconds. Jump, dash, smash through multiple levels and boss fights to save the princess!',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Designer / Programmer"
            },
            {
                icon: profiles_data.stamp.icon,
                name: profiles_data.stamp.first,
                role: "Designer / Programmer"
            },
        ]
    },

    ranchRusher: {
        icon: ranch_rusher_game_icon.default,
        topLine: 'School Project',
        headline: 'Ranch Rusher',
        description: 'Ranch Rushers is a game designed for a 4 players-competitive couch party game. Players will be playing as farmers to compete in a free for all chaotic tournament that is set in the world of Arcadia. The objective of the game is to sell crops and make as much money as possible. You can sabotage and steal crops from other farmers or you can play as a normal peaceful farmer. The player that scores the highest by the end of the round wins!',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.niphon.icon,
                name: profiles_data.niphon.first,
                role: "Project Manager / Lead Programmer"
            },
        ]
    },

    alchemistGrove: {
        icon: alchemist_grove_game_icon.default,
        topLine: 'School Project',
        headline: "Alchemist's Grove",
        description: 'Have you ever seen a 3D First person rogue-like game where the player uses potions for the primary weapon? You can craft potions in the game too! By using ingredients from monster drop. There are 15 potions you can craft in the game.',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.stamp.icon,
                name: profiles_data.stamp.first,
                role: "Creative Director / Programmer"
            },
        ]
    },

    groundian: {
        icon: groundian_game_icon.default,
        topLine: 'School Project',
        headline: "Groundian",
        description: 'Groundian is a top-down action-adventure puzzle game with cartoon graphics playing from Hell going up to Heaven.',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.jan.icon,
                name: profiles_data.jan.first,
                role: "Art Director"
            },
        ]
    },

    switch_g: {
        icon: switch_game_icon.default,
        topLine: 'School Project',
        headline: "Switch",
        description: 'Switch is a 2d side-scroller puzzle platformer game. As a player, you have the power to move between the future and the past. Use the power to pass through obstacles, mutated monsters, and natural disasters to save your world!',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.jame.icon,
                name: profiles_data.jame.first,
                role: "Designer / Programmer"
            },
        ]
    }
    
}