import React, {useRef, useState} from 'react'
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed'
import { 
    GamesContainer, 
    GamesH1,
    GamesWrapper,
    GamesCard, 
    GamesIcon, 
    GamesIconOverly, 
    GameDetail, 
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    MemberGroup,
    MemberWrapper,
    MemberProfileWrapper, 
    MemberProfile, 
    MemberDetailWrapper,
    MemberName,
    MemberRole,
    VideoWrap,
    Button
} from './PrevProjectsElements'

const PrevProjects = ({hoopTheLoop, swordArena, tactica, timeoutHero, ranchRusher, alchemistGrove, groundian, switch_g}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  React.useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
}, []);

  return (
    <GamesContainer>
        <GamesH1>Previous Projects</GamesH1>
        <GamesWrapper>
            <GamesCard>
                <GamesIcon src={ranchRusher.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{ranchRusher.topLine}</TopLine>
                            <Heading>{ranchRusher.headline}</Heading>
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='4WDM-vOCxuM'/>
                            </VideoWrap>
                            <Subtitle>{ranchRusher.description}</Subtitle>
                            <BtnWrap>
                                <Button href="https://www.gamejolt.com/games/ranchrushers/475399" target="_blank"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={true}
                                dark={true}
                                dark2={true}
                                >{ranchRusher.buttonLable}</Button>
                            </BtnWrap>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={ranchRusher.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{ranchRusher.members[0].name}</MemberName>
                                        <MemberRole>{ranchRusher.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
            <GamesCard>
                <GamesIcon src={timeoutHero.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{timeoutHero.topLine}</TopLine>
                            <Heading>{timeoutHero.headline}</Heading>
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='C7Rgc4MzRuA'/>
                            </VideoWrap>
                            <Subtitle>{timeoutHero.description}</Subtitle>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={timeoutHero.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{timeoutHero.members[0].name}</MemberName>
                                        <MemberRole>{timeoutHero.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={timeoutHero.members[1].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{timeoutHero.members[1].name}</MemberName>
                                        <MemberRole>{timeoutHero.members[1].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
            <GamesCard>
                <GamesIcon src={swordArena.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{swordArena.topLine}</TopLine>
                            <Heading>{swordArena.headline}</Heading>
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='9HG8H3qtQEE'/>
                            </VideoWrap>
                            <Subtitle>{swordArena.description}</Subtitle>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={swordArena.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{swordArena.members[0].name}</MemberName>
                                        <MemberRole>{swordArena.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
            <GamesCard>
                <GamesIcon src={hoopTheLoop.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{hoopTheLoop.topLine}</TopLine>
                            <Heading>{hoopTheLoop.headline}</Heading>
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='cK4Wr60_0rA'/>
                            </VideoWrap>
                            <Subtitle>{hoopTheLoop.description}</Subtitle>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={hoopTheLoop.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{hoopTheLoop.members[0].name}</MemberName>
                                        <MemberRole>{hoopTheLoop.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={hoopTheLoop.members[1].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{hoopTheLoop.members[1].name}</MemberName>
                                        <MemberRole>{hoopTheLoop.members[1].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
        </GamesWrapper>
    </GamesContainer>
  )
}

export default PrevProjects