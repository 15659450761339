import React, {useRef, useState} from 'react'
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed'
import { ButtonLink } from '../ButtonElements'
import { 
    GamesContainer, 
    GamesH1,
    GamesWrapper,
    GamesCard, 
    GamesIcon, 
    GamesIconOverly,
    GameDetail, 
    TextWrapper, 
    // FloatButton,
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap, 
    MemberGroup,
    MemberWrapper, 
    MemberProfileWrapper, 
    MemberProfile, 
    MemberDetailWrapper,
    MemberName,
    MemberRole,
    VideoWrap,
    // ArrowForward,
    // ArrowRight,
    Button
} from './GamesElements'

const Games = ({asuji, encodedwar, crushAndRoll, somnolent}) => {
    const ref = useRef(null);

    // const [hover, setHover] = useState(false)

    // const onHover = () => {
    //     setHover(!hover)
    // }

    const [width, setWidth] = useState(0);
  
    React.useLayoutEffect(() => {
      setWidth(ref.current.offsetWidth);
  }, []);
  
  return (
    <GamesContainer id="games">
        <GamesH1>Our Games</GamesH1>
        <GamesWrapper>
            <GamesCard>
                <GamesIcon src={asuji.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{asuji.topLine}</TopLine>
                            <Heading>{asuji.headline}</Heading>
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='hJ65h9gFCWI'/>
                            </VideoWrap>
                            <Subtitle>{asuji.description}</Subtitle>
                            <BtnWrap>
                                <Button href="https://store.steampowered.com/app/2337910/Asuji_The_Legend_of_You/" target="_blank"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={true}
                                dark={true}
                                dark2={true}
                                >{asuji.buttonLable}</Button>
                            </BtnWrap>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[0].name}</MemberName>
                                        <MemberRole>{asuji.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[1].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[1].name}</MemberName>
                                        <MemberRole>{asuji.members[1].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[2].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[2].name}</MemberName>
                                        <MemberRole>{asuji.members[2].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[3].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[3].name}</MemberName>
                                        <MemberRole>{asuji.members[3].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[4].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[4].name}</MemberName>
                                        <MemberRole>{asuji.members[4].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[5].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[5].name}</MemberName>
                                        <MemberRole>{asuji.members[5].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[6].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[6].name}</MemberName>
                                        <MemberRole>{asuji.members[6].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={asuji.members[7].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{asuji.members[7].name}</MemberName>
                                        <MemberRole>{asuji.members[7].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
            <GamesCard>
                <GamesIcon src={encodedwar.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{encodedwar.topLine}</TopLine>
                            <Heading>{encodedwar.headline}</Heading>
                            {/* <FloatButton>
                                <BtnWrap>
                                    <ButtonLink to="/crushandroll"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={true}
                                    dark={true}
                                    dark2={true}
                                    onMouseEnter={onHover} onMouseLeave={onHover}
                                    >View Page{hover ? <ArrowForward /> : <ArrowRight />}</ButtonLink>
                                </BtnWrap>
                            </FloatButton> */}
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='xvu48qe1okI'/>
                            </VideoWrap>
                            <Subtitle>{encodedwar.description}</Subtitle>
                            <BtnWrap>
                                <Button href="https://store.steampowered.com/app/2220070/Encoded_War/" target="_blank"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={true}
                                dark={true}
                                dark2={true}
                                >{encodedwar.buttonLable}</Button>
                            </BtnWrap>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[0].name}</MemberName>
                                        <MemberRole>{encodedwar.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[1].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[1].name}</MemberName>
                                        <MemberRole>{encodedwar.members[1].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[2].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[2].name}</MemberName>
                                        <MemberRole>{encodedwar.members[2].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[3].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[3].name}</MemberName>
                                        <MemberRole>{encodedwar.members[3].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[4].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[4].name}</MemberName>
                                        <MemberRole>{encodedwar.members[4].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[5].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[5].name}</MemberName>
                                        <MemberRole>{encodedwar.members[5].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[6].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[6].name}</MemberName>
                                        <MemberRole>{encodedwar.members[6].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={encodedwar.members[7].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{encodedwar.members[7].name}</MemberName>
                                        <MemberRole>{encodedwar.members[7].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
            <GamesCard>
                <GamesIcon src={crushAndRoll.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{crushAndRoll.topLine}</TopLine>
                            <Heading>{crushAndRoll.headline}</Heading>
                            {/* <FloatButton>
                                <BtnWrap>
                                    <ButtonLink to="/crushandroll"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={true}
                                    dark={true}
                                    dark2={true}
                                    onMouseEnter={onHover} onMouseLeave={onHover}
                                    >View Page{hover ? <ArrowForward /> : <ArrowRight />}</ButtonLink>
                                </BtnWrap>
                            </FloatButton> */}
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='Jz6tTXCOAnQ'/>
                            </VideoWrap>
                            <Subtitle>{crushAndRoll.description}</Subtitle>
                            <BtnWrap>
                                <ButtonLink to="highlight_crushAndRoll"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact="true"
                                offset={-80}
                                primary={true}
                                dark={true}
                                dark2={true}
                                >{crushAndRoll.buttonLable}</ButtonLink>
                            </BtnWrap>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={crushAndRoll.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{crushAndRoll.members[0].name}</MemberName>
                                        <MemberRole>{crushAndRoll.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={crushAndRoll.members[1].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{crushAndRoll.members[1].name}</MemberName>
                                        <MemberRole>{crushAndRoll.members[1].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={crushAndRoll.members[2].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{crushAndRoll.members[2].name}</MemberName>
                                        <MemberRole>{crushAndRoll.members[2].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={crushAndRoll.members[3].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{crushAndRoll.members[3].name}</MemberName>
                                        <MemberRole>{crushAndRoll.members[3].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={crushAndRoll.members[4].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{crushAndRoll.members[4].name}</MemberName>
                                        <MemberRole>{crushAndRoll.members[4].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
            <GamesCard>
                <GamesIcon src={somnolent.icon} />
                <GamesIconOverly>
                    <GameDetail>
                        <TextWrapper>
                            <TopLine>{somnolent.topLine}</TopLine>
                            <Heading>{somnolent.headline}</Heading>
                            <VideoWrap ref={ref}>
                                <YoutubeEmbed width={width} height={width * 9/16} embedId='BsRoUI4W8tI'/>
                            </VideoWrap>
                            <Subtitle>{somnolent.description}</Subtitle>
                            <MemberGroup>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={somnolent.members[0].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{somnolent.members[0].name}</MemberName>
                                        <MemberRole>{somnolent.members[0].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                                <MemberWrapper>
                                    <MemberProfileWrapper>
                                        <MemberProfile src={somnolent.members[1].icon}/>
                                    </MemberProfileWrapper>
                                    <MemberDetailWrapper>
                                        <MemberName>{somnolent.members[1].name}</MemberName>
                                        <MemberRole>{somnolent.members[1].role}</MemberRole>
                                    </MemberDetailWrapper>
                                </MemberWrapper>
                            </MemberGroup>
                        </TextWrapper>
                    </GameDetail>
                </GamesIconOverly>
            </GamesCard>
        </GamesWrapper>
    </GamesContainer>
  )
}

export default Games