import * as asuji_the_legend_of_you_game_icon from '../../images/games-title/asuji_the_legend_of_you_game_icon.png';
import * as encodedwar_game_icon from '../../images/games-title/encodedwar_game_icon.png';
import * as crushandroll_game_icon from '../../images/games-title/crushandroll_game_icon.png';
import * as somnolent_game_icon from '../../images/games-title/somnolent_game_icon.png';

import { profiles_data } from '../AboutUs/Data';

export const games_data = {
    asuji: {
        icon: asuji_the_legend_of_you_game_icon.default,
        topLine: 'Steam',
        headline: 'Asuji: The Legend of You',
        description: 'After studying Biology for 7 minutes, we are proud to present this masterpiece sperm-like game. Play as a sperm that everyone makes fun of, because your tail size is very below average. Prove them wrong in your journey towards becoming a human baby!',
        buttonLable: 'Purchase Now',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Creative Director / Lead Designer"
            },
            {
                icon: profiles_data.niphon.icon,
                name: profiles_data.niphon.first,
                role: "Lead Producer / Lead Programmer"
            },
            {
                icon: profiles_data.first.icon,
                name: profiles_data.first.first,
                role: "Art Director / Artist"
            },
            {
                icon: profiles_data.gno.icon,
                name: profiles_data.gno.first,
                role: "Programmer"
            },
            {
                icon: profiles_data.pim.icon,
                name: profiles_data.pim.first,
                role: "Character Designer"
            },
            {
                icon: profiles_data.pea.icon,
                name: profiles_data.pea.first,
                role: "Environment Artist"
            },
            {
                icon: profiles_data.milk.icon,
                name: profiles_data.milk.first,
                role: "Assets Artist"
            },
            {
                icon: profiles_data.sun.icon,
                name: profiles_data.sun.first,
                role: "Designer"
            }
        ]
    },
    encodedwar: {
        icon: encodedwar_game_icon.default,
        topLine: 'Steam',
        headline: 'Encoded War',
        description: 'Encoded War is a puzzle simulation game where you play as a morse sender during the World War 2. Each day comes with new tasks for you to overcome and challenges your morse coding skill.',
        buttonLable: 'Purchase Now',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Creative Director / Lead Designer"
            },
            {
                icon: profiles_data.niphon.icon,
                name: profiles_data.niphon.first,
                role: "Lead Producer / Lead Programmer"
            },
            {
                icon: profiles_data.jan.icon,
                name: profiles_data.jan.first,
                role: "Art Director / Artist"
            },
            {
                icon: profiles_data.gno.icon,
                name: profiles_data.gno.first,
                role: "Programmer"
            },
            {
                icon: profiles_data.stamp.icon,
                name: profiles_data.stamp.first,
                role: "Designer"
            },
            {
                icon: profiles_data.jame.icon,
                name: profiles_data.jame.first,
                role: "Designer"
            },
            {
                icon: profiles_data.ryan.icon,
                name: profiles_data.ryan.first,
                role: "Designer"
            },
            {
                icon: profiles_data.jaja.icon,
                name: profiles_data.jaja.first,
                role: "Community Manager"
            }
        ]
    },
    crushAndRoll: {
        icon: crushandroll_game_icon.default,
        topLine: 'GMTK Game Jam 2022',
        headline: 'Crush and Roll',
        description: 'Crush and Roll is a 1v1 party game made in under 48 hours for GMTK Game Jam 2022 - Roll of the Dice. Crush your dice and compete against each other inside the colorful but chaotic levels. Fill up your dice faces with the highest number possible, then screw your friends over!',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Lead Designer"
            },
            {
                icon: profiles_data.niphon.icon,
                name: profiles_data.niphon.first,
                role: "Lead Programmer"
            },
            {
                icon: profiles_data.jan.icon,
                name: profiles_data.jan.first,
                role: "Art Director"
            },
            {
                icon: profiles_data.stamp.icon,
                name: profiles_data.stamp.first,
                role: "Designer"
            },
            {
                icon: profiles_data.jame.icon,
                name: profiles_data.jame.first,
                role: "Programmer"
            }
        ]
    },

    somnolent: {
        icon: somnolent_game_icon.default,
        topLine: 'Game Project Year 3',
        headline: 'Somnolent',
        description: 'Feeling weak? Find the drug and inject! Somnolent is a minimalist top-down action game where you are weak while awake and slaughter while asleep. With an impactful stylish heart-pumping action combat, fight through hordes of enemies and leave no one alive.',
        buttonLable: 'Check it out',
        members: [
            {
                icon: profiles_data.kongtai.icon,
                name: profiles_data.kongtai.first,
                role: "Designer"
            },
            {
                icon: profiles_data.niphon.icon,
                name: profiles_data.niphon.first,
                role: "Programmer"
            }
        ]
    }
    
}