import React from 'react'
import './styles.css'
import * as obj_1 from '../../images/falling_diceface/falling_1.png';
import * as obj_2 from '../../images/falling_diceface/falling_2.png';
import * as obj_3 from '../../images/falling_diceface/falling_3.png';
import * as obj_4 from '../../images/falling_diceface/falling_4.png';
import * as obj_5 from '../../images/falling_diceface/falling_5.png';
import * as obj_6 from '../../images/falling_diceface/falling_6.png';
import * as obj_7 from '../../images/falling_diceface/falling_7.png';

function FallingObjectsAnimation() {
  return (
    <div>
        <div className="object">
        <div>  <img src={obj_1.default} alt="" /></div>
        <div>  <img src={obj_2.default} alt="" /></div>
        <div>  <img src={obj_3.default} alt="" /></div>
        <div>  <img src={obj_4.default} alt="" /></div>
        <div>  <img src={obj_5.default} alt="" /></div>
        <div>  <img src={obj_6.default} alt="" /></div>
        <div>  <img src={obj_7.default} alt="" /></div>
        </div>
    </div>
  )
}

export default FallingObjectsAnimation