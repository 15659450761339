import React, {useState} from 'react'
import ReactTypingEffect from 'react-typing-effect'
import Video from '../../videos/video.mp4'
import { ButtonLink } from '../ButtonElements'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, InvisibleText } from './HeroElements'

const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }


  return (
    <HeroContainer>
        <InvisibleText>Somnolent team, </InvisibleText>
        <HeroBg>
          <VideoBg autoPlay loop muted src={Video} type='video/mp4' disablePictureInPicture={true}/>
        </HeroBg>
        <HeroContent>
          <HeroH1><ReactTypingEffect text={["We love creating Awesome games!", "We love working on Cool ideas!"]} typingDelay={1000} eraseDelay={2000} eraseSpeed={50} speed={100} /></HeroH1>
          <HeroP>
          Somnolent team is a group of indie game developers who are passionate about creating games with awesome ideas. With our experience of creating multiple game projects, we will continue making unique fun games that you cannot resist playing
          </HeroP>
          <HeroBtnWrapper>
            <ButtonLink to="games" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true"
            smooth={true} duration={500} spy={true} exact='true' offset={-80}>
              Our Games {hover ? <ArrowForward /> : <ArrowRight />}
            </ButtonLink>
          </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection;