import React from 'react'
import {animateScroll as scroll} from 'react-scroll'
import {FaFacebook, FaTwitter, FaYoutube, FaTiktok} from 'react-icons/fa'
import {FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink} from './FooterElements'

function Footer() {
    const toggleHome = () => {
        scroll.scrollToTop();
      }
  
    return (
    <>
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            Somnolent Team
                        </SocialLogo>
                        <WebsiteRights>Somnolent Team © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//www.facebook.com/somnolent.team" target="_blank"
                            aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="//www.twitter.com/SomnolentTeam" target="_blank"
                            aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="//www.youtube.com/channel/UC8xkYTDK18wMOlpmUdpT2KA/?sub_confirmation=1" target="_blank"
                            aria-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.tiktok.com/@somnolent.team" target="_blank"
                            aria-label="Tiktok">
                                <FaTiktok />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    </>
  )
}

export default Footer