import React from 'react'
import {FaFacebook, FaTwitter, FaYoutube, FaTiktok} from 'react-icons/fa'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute, SocialIcons, SocialIconLink } from './SidebarElements'

export const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="home" onClick={toggle}>Home</SidebarLink>
                <SidebarLink to="games" onClick={toggle}>Games</SidebarLink>
                <SidebarLink to="about-us" onClick={toggle}>About Us</SidebarLink>
                <SocialIcons>
                    <SocialIconLink href="//www.facebook.com/somnolent.team" target="_blank"
                    aria-label="Facebook">
                        <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink href="//www.twitter.com/SomnolentTeam" target="_blank"
                    aria-label="Twitter">
                        <FaTwitter />
                    </SocialIconLink>
                    <SocialIconLink href="//www.youtube.com/channel/UC8xkYTDK18wMOlpmUdpT2KA/?sub_confirmation=1" target="_blank"
                    aria-label="Youtube">
                        <FaYoutube />
                    </SocialIconLink>
                    <SocialIconLink href="https://www.tiktok.com/@somnolent.team" target="_blank"
                    aria-label="Tiktok">
                        <FaTiktok />
                    </SocialIconLink>
                </SocialIcons>
            </SidebarMenu>
            <SideBtnWrap>
                <SidebarRoute to="/contact">Contact Us</SidebarRoute>
            </SideBtnWrap>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar