import * as crushAndRoll_svg_1 from '../../images/highlight/crushandroll_decor.png';
import * as crushAndRoll_svg_2 from '../../images/highlight/crushandroll_bg.png';
import * as encodedwar_svg_1 from '../../images/highlight/encodedwar_bg.png';
import * as asuji_svg_1 from '../../images/highlight/asuji_the_legend_of_you_bg.png';
import * as asuji_svg_2 from '../../images/highlight/asuji_the_legend_of_you_mc.png';

export const highlight_datas = {
    asuji: {
        id: 'home',
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: 'Our Latest Project',
        headline: 'Asuji: The Legend of You',
        description: 'After studying Biology for 7 minutes, we are proud to present this masterpiece sperm-like game. Play as a sperm that everyone makes fun of, because your tail size is very below average. Prove them wrong in your journey towards becoming a human baby!',
        buttonLable: 'Steam Page',
        imgStart: false,
        img: asuji_svg_1.default,
        img2: asuji_svg_2.default,
        alt: 'Asuji: The Legend of You background',
        dark: true,
        primary: true,
        darkText: false
    },
    encodedwar: {
        id: 'highlight_encodedwar',
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: 'Feature',
        headline: 'Encoded War',
        description: 'Encoded War is a puzzle simulation game where you play as a morse sender during the World War 2. Each day comes with new tasks for you to overcome and challenges your morse coding skill.',
        buttonLable: 'Steam Page',
        imgStart: true,
        img: encodedwar_svg_1.default,
        alt: 'Encoded War background',
        dark: true,
        primary: true,
        darkText: false
    },
    crushAndRoll: {
        id: 'highlight_crushAndRoll',
        lightBg: false,
        lightText: true,
        lightTextDesc: true,
        topLine: 'Feature',
        headline: 'Crush and Roll',
        description: 'Crush and Roll is a 1v1 party game made in under 48 hours for GMTK Game Jam 2022 - Roll of the Dice. Crush your dice and compete against each other inside the colorful but chaotic levels. Fill up your dice faces with the highest number possible, then screw your friends over!',
        buttonLable: 'Game Page',
        imgStart: false,
        img: crushAndRoll_svg_1.default,
        img2: crushAndRoll_svg_2.default,
        alt: 'Crush and Roll background',
        dark: true,
        primary: true,
        darkText: false
    }
}