import React from 'react'
import 
{ 
    MemberGroup, 
    MemberCard, 
    MemberWrapper, 
    MemberProfileWrapper, 
    MemberProfile, 
    MemberName, 
    MemberDetail, 
    MemberRole, 
    AboutUsWrapper, 
    AboutUsTitle,
    SocialIcons, 
    SocialIconLink,
    ReleaseTitleH1,
    ReleaseTitlesWrapper,
    ReleaseTitle
} from './AboutUsElements'

import { profiles_data } from './Data'
import { FaInstagram} from 'react-icons/fa'
import { BsFacebook, BsLinkedin } from 'react-icons/bs'

const AboutUsSection = () => {
  return (
    <>
    <AboutUsWrapper id="about-us">
        <AboutUsTitle>Team Members</AboutUsTitle>
        <MemberGroup>
            <MemberCard>
                <MemberWrapper>
                    <MemberProfileWrapper>
                        <MemberProfile src={profiles_data.niphon.icon}/>
                    </MemberProfileWrapper>
                    <MemberName>{profiles_data.niphon.first + ' ' + profiles_data.niphon.last}</MemberName>
                    <MemberRole>{profiles_data.niphon.role}</MemberRole>
                    <MemberDetail>{profiles_data.niphon.description}</MemberDetail>
                    <ReleaseTitleH1>Release Titles</ReleaseTitleH1>
                    <ReleaseTitlesWrapper>
                        <ReleaseTitle href={profiles_data.niphon.release_titles[0].link} target="_blank">
                            {profiles_data.niphon.release_titles[0].name + " |"}
                        </ReleaseTitle>
                        <ReleaseTitle href={profiles_data.niphon.release_titles[1].link} target="_blank">
                            {profiles_data.niphon.release_titles[1].name + " |"}
                        </ReleaseTitle>
                        <ReleaseTitle href={profiles_data.niphon.release_titles[2].link} target="_blank">
                            {profiles_data.niphon.release_titles[2].name}
                        </ReleaseTitle>
                    </ReleaseTitlesWrapper>
                    <SocialIcons>
                        <SocialIconLink href="//www.linkedin.com/in/kokkak-niphon-48a498204/" target="_blank"
                        aria-label="Linkedin">
                            <BsLinkedin />
                        </SocialIconLink>
                        <SocialIconLink href="//www.instagram.com/kokkak.niphon/" target="_blank"
                        aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="https://web.facebook.com/L11.15.11.11.1.11" target="_blank"
                        aria-label="Facebook">
                            <BsFacebook />
                        </SocialIconLink>
                    </SocialIcons>
                </MemberWrapper>
            </MemberCard>
            <MemberCard>
                <MemberWrapper>
                    <MemberProfileWrapper>
                        <MemberProfile src={profiles_data.kongtai.icon}/>
                    </MemberProfileWrapper>
                    <MemberName>{profiles_data.kongtai.first + ' ' + profiles_data.kongtai.last}</MemberName>
                    <MemberRole>{profiles_data.kongtai.role}</MemberRole>
                    <MemberDetail>{profiles_data.kongtai.description}</MemberDetail>
                    <SocialIcons>
                        <SocialIconLink href="//www.linkedin.com/in/kongtaipokapalakorn/" target="_blank"
                        aria-label="Linkedin">
                            <BsLinkedin />
                        </SocialIconLink>
                        <SocialIconLink href="https://web.facebook.com/profile.php?id=100091270683385" target="_blank"
                        aria-label="Facebook">
                            <BsFacebook />
                        </SocialIconLink>
                    </SocialIcons>
                </MemberWrapper>
            </MemberCard>
        </MemberGroup>      
    </AboutUsWrapper>
    </>
  )
}

export default AboutUsSection