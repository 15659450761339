import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';

export const GamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 64px;
  padding-bottom: 64px;
  align-items: center;
  background: #010606;

  @media screen and (max-width: 1400px){
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 480px){
    font-size: 2rem;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`

export const GamesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 64px;

    @media screen and (max-width: 1400px){
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }
`

export const GamesCard = styled.div`
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: 40vw;
  max-height: auto;
  margin-left: 15px;
  margin-right: 15px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 10px 5px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  position: relative;
  
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }


  @media screen and (max-width: 1400px){
    width: 80vw;
    border-radius: 16px;
  }
`

export const GamesIcon = styled.img`
  position: relative;
  border-radius: inherit;
  overflow: hidden;
  height: inherit;
  width: inherit;
  box-sizing: inherit;
  z-index: 0;
`

export const GamesIconOverly = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: inherit;
  background: linear-gradient(0deg,rgba(0,0,0,.5),transparent 50%,transparent 70%,rgba(0,0,0,.25));  
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: rgba(1,6,6,0.9);
  }
  z-index: 1;
`

export const GamesH1 = styled.h1`
  font-size: 2.5rem;
  color: #44CFCB;
  margin-bottom: 64px;
  text-shadow: 0px 2px 2px rgba(0,0,0,0.3);

  @media screen and (max-width: 480px){
    font-size: 2rem;
    margin-bottom: 24px;
  }
`

export const GamesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`

export const GamesP = styled.p`
  font-size: 1rem;
  text-align: center;
`


export const TextWrapper = styled.div`
 max-width: 100%;
 display: block;
 position: absolute;
 transition: all 0.5s ease-in-out;
 padding: 5%;
 margin-top: 150%;
`

export const GameDetail = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  overflow: hidden;
  -ms-overflow-style: none; 
  scrollbar-width: none;

  &::-webkit-scrollbar {
     display: none;
  }

  &:hover {
    ${TextWrapper} {
      margin-top: 0;
    }

    overflow: scroll;
  }
`
export const FloatButton = styled.div`
  position: absolute;
  float: right;
  right: 16px;
  top: 16px;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`


export const TopLine = styled.p`
  color: #44CFCB;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1.%;
  font-weight: 600;
  color: #f7f8fa;

  @media screen and (max-width: 480px){
    font-size: 32px;
  }
`

export const Subtitle = styled.p`
  max-width: 100%;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
`
export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5%;
`

export const MemberGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 480px){
    grid-template-columns: 1fr;
  }
`

export const MemberWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px;
`

export const MemberProfileWrapper = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
`

export const MemberProfile = styled.img`
  position: relative;
  border-radius: inherit;
  overflow: hidden;
  height: inherit;
  width: inherit;
  box-sizing: inherit;

  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`
export const MemberDetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`

export const MemberName = styled.p`
  color: #fff;
  margin-left: 12px;
  font-style: normal;
  font-weight: bold;
  @media screen and (max-width: 480px){
    font-size: 1rem;
  }
`

export const MemberRole = styled.p`
  color: #aaa;
  margin-left: 10px;
  font-style: italic;
  @media screen and (max-width: 480px){
    font-size: 1rem;
  }
`

export const VideoWrap = styled.div`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 16px;
`

export const Button = styled.a`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#44CFCB' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#122C34' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2 ease-in-out;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    transition: all 0.2 ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#44CFCB')};
  }
`