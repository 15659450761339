import styled from "styled-components";

export const AboutUsWrapper = styled.div`
  background: #f7f8fa;
`

export const AboutUsTitle = styled.h1`
  font-size: 2.5rem;
  color: #010606;
  padding-top: 64px;
  text-align: center;
  text-shadow: 0px 2px 2px rgba(0,0,0,0.3);

  @media screen and (max-width: 480px){
    font-size: 2rem;
    padding-top: 24px;
  }
`

export const MemberGroup = styled.div`
  display: grid;
  justify-content: space-evenly;
  justify-items: center;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 64px;
  padding-top: 64px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1400px){
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 1000px){
    grid-template-columns: 1fr;
  }
`
export const MemberCard = styled.div`
  background: #010606;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  width: 480px;
  height: 640px;
  margin: 15px;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 10px 5px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  position: relative;

  
  overflow: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;

  &::-webkit-scrollbar {
     display: none;
  }

  @media screen and (max-width: 480px){
    width: 280px;
    height: 440px;
  }
`

export const MemberWrapper = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  margin: 8px;
`

export const MemberProfileWrapper = styled.div`
  width: 260px;
  height: 260px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 32px;
  background-color: #fff;
  outline: #fff solid 8px;

  @media screen and (max-width: 480px){
    width: 120px;
    height: 120px;
    border-radius: 60px;
    outline: #fff solid 5px;
  }
`

export const MemberProfile = styled.img`
  position: relative;
  border-radius: inherit;
  overflow: hidden;
  height: inherit;
  width: inherit;
  box-sizing: inherit;

  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`
export const MemberName = styled.p`
  color: #f7f8fa;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;

  @media screen and (max-width: 480px){
    font-size: 20px;
  }
`
export const MemberDetail= styled.p`
  color: #c9c9c9;
  font-size: 18px;
  font-weight: 550;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 3%;
  padding-top: 3%;
  line-height: 21px;

  @media screen and (max-width: 480px){
    font-size: 16px;
  }
`
export const MemberRole = styled.p`
  color: #44CFCB;
  font-size: 22px;
  font-weight: bold;
  text-align: center;

  @media screen and (max-width: 480px){
    font-size: 19px;
  }
`

export const SocialIcons = styled.div`
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  margin-bottom: 16px;

  @media screen and (max-width: 480px){
    position: relative;
  }
`

export const SocialIconLink = styled.a`
  width: 24px;
  margin: 5px;
  color: #fff;
  font-size: 24px;
`

export const ReleaseTitleH1= styled.p`
  color: #c9c9c9;
  font-size: 16px;
  margin-top: -3%;
  margin-bottom: 5px;
  font-weight: 550;

  @media screen and (max-width: 480px){
    margin-top: 0;
  }
`

export const ReleaseTitlesWrapper = styled.div`
  position: relative;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-bottom: 16px;
  grid-template-rows: 1fr;
  padding-left: 5%;
  padding-right: 5%;
`

export const ReleaseTitle = styled.a`
  margin: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
`