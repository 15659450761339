import * as niphon_icon from '../../images/team-profiles/niphon_icon.jpg';
import * as kongtai_icon from '../../images/team-profiles/kongtai_icon.jpg';
import * as stamp_icon from '../../images/team-profiles/stamp_icon.jpg';
import * as jan_icon from '../../images/team-profiles/jan_icon.jpg';
import * as jame_icon from '../../images/team-profiles/jame_icon.jpg';
import * as ryan_icon from '../../images/team-profiles/ryan_icon.jpg';
import * as jaja_icon from '../../images/team-profiles/jaja_icon.jpg';
import * as gno_icon from '../../images/team-profiles/gno_icon.jpg';
import * as first_icon from '../../images/team-profiles/first_icon.jpg';
import * as milk_icon from '../../images/team-profiles/milk_icon.jpg';
import * as pea_icon from '../../images/team-profiles/pea_icon.jpg';
import * as pim_icon from '../../images/team-profiles/pim_icon.jpg';
import * as sun_icon from '../../images/team-profiles/sun_icon.jpg';

export const profiles_data = {
    
    niphon: {
        icon: niphon_icon.default,
        first: 'Niphon',
        last: 'Prasopphol',
        role: 'Lead Producer + Lead Programmer',
        description: "I have always been interested in game development. I started working as a game programmer when I was 17, and now I'm working as a Software Engineer at Earthshine Games with over 5 years of experience. I'm eager to learn new things and looking forward to improve myself everyday!",
        release_titles: [
            {
                name: 'Too Many Cooks',
                link: 'https://play.google.com/store/apps/details?id=com.playstack.toomanycooks&hl=th&gl=US'
            },
            {
                name: 'Otter Ocean',
                link: 'https://play.google.com/store/apps/details?id=com.FinifuguGames.google.OtterOcean&hl=th&gl=US'
            },
            {
                name: 'Pepo Paradise',
                link: 'https://pepoparadise.com'
            },
        ]
    },
    kongtai: {
        icon: kongtai_icon.default,
        first: 'Kongtai',
        last: 'Pokapalakorn',
        role: 'Creative Director + Lead Designer',
        description: "Greetings everyone who's reading this, my name is Kong. I like to play games in my freetime, especially unique indie games, and try to learn how each of the game mechanics are implemented. I sometimes create my own personal indie games and graphic design works. So far, I enjoy working on game development and creating something super fun and engaging.",
    },
    stamp: {
        icon: stamp_icon.default,
        first: 'Jirakit',
        last: 'Jarusiripipat',
        role: 'Programmer / Designer',
        description: "Hello, my name is Jirakit. I'm a designer who gives more than 100% for games development. I'm interested in playing games as a leisure activity. I always study the new game when it is released. I will analyze each game to do my research. I love working in game development and creating superb games in the future.",
    },
    jan: {
        icon: jan_icon.default,
        first: 'Awatsada',
        last: 'Sawasdichai',
        role: 'Art Director + 2D Artist',
        description: "Hi everyone!, my name is Jan, I'm a Concept artist / Illustrator for animation and games based in Thailand. I specialize in both 2D and 3D art (but am always eager to learn and improve my skills)",
    },
    jame: {
        icon: jame_icon.default,
        first: 'Thitimes',
        last: 'Suriyasenee',
        role: 'Programmer',
        description: "Hi everyone, my name is Jame. I'm a game and keyboard enthusiast, I like to play all kinds of games and try to learn from the games that I play about game design and mechanics. I also like to create games myself. my dream is to make a game that makes everyone that plays it enjoy and have a good time.",
    },
    ryan: {
        icon: ryan_icon.default,
        first: 'Ryan',
        last: 'Cua',
        role: 'Designer',
    },
    jaja: {
        icon: jaja_icon.default,
        first: 'Aunchisa',
        last: 'Tuntarungsri',
        role: 'Community Manager',
    },
    gno: {
        icon: gno_icon.default,
        first: 'Akira',
        last: 'Ruedeesin',
        role: 'Programmer',
    },
    first: {
        icon: first_icon.default,
        first: 'Nattathida',
        last: 'Huaybun',
        role: 'Art Director + 2D Artist',
    },
    milk: {
        icon: milk_icon.default,
        first: 'Thitaree',
        last: 'Weerapatinya',
        role: '2D Artist',
    },
    pea: {
        icon: pea_icon.default,
        first: 'Praewa',
        last: 'Ekphech',
        role: '2D Artist',
    },
    pim: {
        icon: pim_icon.default,
        first: 'Nawaphat',
        last: 'Tamsamran',
        role: '2D Artist',
    },
    sun: {
        icon: sun_icon.default,
        first: 'Peerawich',
        last: 'Sinamornwech',
        role: 'Designer',
    }
}