import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100vh;
  width: 100%;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: right;
`

export const ImgBg = styled.img`
  position: absolute;
  padding-top: 0px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 0;
`

export const ImgBgOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(0,0,0,0.5);
`

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: 1fr 2fr;
  justify-items: auto;
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 768px){
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  }
`

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  z-index: 1;
  @media screen and (max-width: 768px){
    margin-bottom: -50px;
  }
`

export const Column2 = styled.div`
  margin-bottom: 105px;
  margin-left: 15px;
  padding: 0 25px;
  grid-area: col2;

  @media screen and (max-width: 768px){
    margin-top: 0%;
    padding: 0;
    margin-left: 0%;
  }
`

export const TextWrapper = styled.div`
 max-width: 540px;
 padding-top: 0;
 padding-bottom: 60px;
`

export const TopLine = styled.p`
  color: #44CFCB;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 64px;
  line-height: 1.1.%;
  font-weight: 600;
  color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};

  @media screen and (max-width: 480px){
    font-size: 48px;
  }
`

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const Button = styled.a`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#44CFCB' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
  color: ${({dark}) => (dark ? '#122C34' : '#fff')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2 ease-in-out;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    transition: all 0.2 ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#44CFCB')};
  }
`

export const VideoWrap = styled.div`
  position: relative;
  /* bottom: -100px; */
  border-radius: 24px;
  overflow: hidden;
  margin: 0 0 0 0;
`

export const ImgWrap = styled.div`
  position: absolute;
  max-width: 500px;
  height: 100%;
  padding-top: 0%;
  margin-top: -50px;
  right: 15vw;
  z-index: 0;
  @media screen and (max-width: 587px) {
    padding-top: 10%;
  }
`

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`


export const ButtonText = styled.a`
  border-radius: 50px;
  background-color: transparent;
  white-space: nowrap;
  padding: 12px;
  color: #ccc;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  &:hover {
    transition: all 0.2 ease-in-out;
    color: #fff;
  }
`