import React, {useState} from 'react'
import HeroSection from '../components/HeroSection'
import { highlight_datas } from '../components/HighlightSection/Data'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Games from '../components/Games'
import Footer from '../components/Footer'
import HighlightSection from '../components/HighlightSection'
import { games_data } from '../components/Games/Data'
import AboutUsSection from '../components/AboutUs'
import PrevProjects from '../components/PrevProjects'
import { prev_projects_data } from '../components/PrevProjects/Data'

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar toggle={toggle} showNavLink={true}/>
      <HeroSection />
      <HighlightSection {...highlight_datas}/>
      {/* <InfoSection {...homeObjOne}/> */}
      <Games {...games_data}/>
      {/* <InfoSection {...homeObjTwo}/> */}
      <AboutUsSection />
      <PrevProjects {...prev_projects_data}/>
      <Footer />
    </>
  )
}

export default Home