import React from 'react'
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, VideoWrap, ImgBg, ImgBgOverlay, Button,
  ButtonText} from './HighlightElements'

import { useState, useEffect } from 'react';
import FallingObjectsAnimation from '../FallingObjectsAnimation';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const HighlightSection = ({asuji, encodedwar, crushAndRoll}) => {
  const { width } = useWindowDimensions();
  
  const oneLineTreshold = 768;
  const maxVodW = 840;
  const minVodW = 300;

  const oneLineMul = 1.15;
  const twoLineMul = 1.8;

  var vodW = (width <= oneLineTreshold) ?  (width/oneLineMul) : (width/twoLineMul);

  function getUseableVodWidth(){
    if(maxVodW > vodW)
      if(minVodW < vodW)
        return vodW;
      else
        return minVodW;
    else return maxVodW;
  }

  return (
    <>
      <InfoContainer lightBg={asuji.lightBg} id={asuji.id}>
        <ImgBg src={asuji.img}/>
        <ImgBgOverlay />
        <InfoWrapper>
            <InfoRow imgStart={asuji.imgStart}>
                <Column1>
                    <TextWrapper>
                        <TopLine>{asuji.topLine}</TopLine>
                        <Heading lightText={asuji.lightText}>{asuji.headline}</Heading>
                        <Subtitle darkText={asuji.darkText}>{asuji.description}</Subtitle>
                        <BtnWrap>
                            <Button to="" href='https://store.steampowered.com/app/2337910/Asuji_The_Legend_of_You/' target="_blank" aria-label="Encoded War"
                            smooth={true}
                            duration={500}
                            exact="true"
                            offset={-80}
                            primary={asuji.primary ? 1 : 0}
                            dark={asuji.dark ? 1 : 0}
                            dark2={asuji.dark2 ? 1 : 0}
                            >{asuji.buttonLable}</Button>
                            <ButtonText href='https://drive.google.com/drive/folders/1l5FulQjSVl45fGjPxHDzAuqeojpxgGHf?usp=sharing' target="_blank">PRESS KIT</ButtonText>
                        </BtnWrap>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <VideoWrap style={{width: getUseableVodWidth(), height: (getUseableVodWidth() * 9/16)}}>
                      <YoutubeEmbed embedId='hJ65h9gFCWI' width={getUseableVodWidth()} height={getUseableVodWidth() * 9/16}/>
                    </VideoWrap>
                    <ImgWrap style={{width: (getUseableVodWidth() * 0.35), paddingLeft: 20}}>
                        <Img src={asuji.img2} alt={asuji.alt} />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      <InfoContainer lightBg={encodedwar.lightBg} id={encodedwar.id}>
        <ImgBg src={encodedwar.img}/>
        <ImgBgOverlay />
        <InfoWrapper>
            <InfoRow imgStart={encodedwar.imgStart}>
                <Column1>
                    <TextWrapper>
                        <TopLine>{encodedwar.topLine}</TopLine>
                        <Heading lightText={encodedwar.lightText}>{encodedwar.headline}</Heading>
                        <Subtitle darkText={encodedwar.darkText}>{encodedwar.description}</Subtitle>
                        <BtnWrap>
                            <Button to="" href='https://store.steampowered.com/app/2220070/Encoded_War/' target="_blank" aria-label="Encoded War"
                            smooth={true}
                            duration={500}
                            exact="true"
                            offset={-80}
                            primary={encodedwar.primary ? 1 : 0}
                            dark={encodedwar.dark ? 1 : 0}
                            dark2={encodedwar.dark2 ? 1 : 0}
                            >{encodedwar.buttonLable}</Button>
                            <ButtonText href='https://drive.google.com/drive/folders/1eMYETu_qt-l1dW8IDnLMypsqJVY6x5rf?usp=sharing' target="_blank">PRESS KIT</ButtonText>
                        </BtnWrap>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <VideoWrap style={{width: getUseableVodWidth(), height: (getUseableVodWidth() * 9/16)}}>
                      <YoutubeEmbed embedId='xvu48qe1okI' width={getUseableVodWidth()} height={getUseableVodWidth() * 9/16}/>
                    </VideoWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
      </InfoContainer>
      <InfoContainer lightBg={crushAndRoll.lightBg} id={crushAndRoll.id}>
        <ImgBg src={crushAndRoll.img2}/>
        <FallingObjectsAnimation />
        <ImgBgOverlay />
        <InfoWrapper>
            <InfoRow imgStart={crushAndRoll.imgStart}>
                <Column1>
                    <TextWrapper>
                        <TopLine>{crushAndRoll.topLine}</TopLine>
                        <Heading lightText={crushAndRoll.lightText}>{crushAndRoll.headline}</Heading>
                        <Subtitle darkText={crushAndRoll.darkText}>{crushAndRoll.description}</Subtitle>
                        <BtnWrap>
                            <Button to="" href='//somnolentteam.itch.io/crush-and-roll-gmtk-game-jam-2022' target="_blank" aria-label="Crush and Roll"
                            smooth={true}
                            duration={500}
                            exact="true"
                            offset={-80}
                            primary={crushAndRoll.primary ? 1 : 0}
                            dark={crushAndRoll.dark ? 1 : 0}
                            dark2={crushAndRoll.dark2 ? 1 : 0}
                            >{crushAndRoll.buttonLable}</Button>
                        </BtnWrap>
                    </TextWrapper>
                </Column1>
                <Column2>
                    <VideoWrap style={{width: getUseableVodWidth(), height: (getUseableVodWidth() * 9/16)}}>
                      <YoutubeEmbed embedId='Jz6tTXCOAnQ' width={getUseableVodWidth()} height={getUseableVodWidth() * 9/16}/>
                    </VideoWrap>
                    <ImgWrap style={{width: (getUseableVodWidth()/2)}}>
                        <Img src={crushAndRoll.img} alt={crushAndRoll.alt} />
                    </ImgWrap>
                </Column2>
            </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default HighlightSection