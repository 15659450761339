import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/home.js'
// import CrushAndRoll from './pages/crushandroll.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} exact></Route>
        {/* <Route path="/crushandroll" element={<CrushAndRoll/>} exact></Route> */}
      </Routes>
    </Router>
  );
}

export default App;
